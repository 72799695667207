<template>
	<form-item label="品类:">
		<el-autocomplete
			class="inline-input"
		    v-model="selectedItem"
		    :fetch-suggestions="querySearch"
		    placeholder="请输入"
		    @select="handleSelect"
			size="mini"
			@input="changedInput"
		></el-autocomplete>
	</form-item>
</template>

<script>
	import {
		mapActions
	} from 'vuex'
	export default {
		props: {
			site: {
				type: [String, Array],
				default: ''
			}
		},
		data() {
			return {
				selectedItem: '',
				items: [],
				lastAbortController: null
			}
		},
		model: {
			prop: "value",
			event: "update"
		},
		watch: {
			lastAbortController(newVal, oldVal) {
				oldVal && oldVal.abort()
			},
			site(newVal, oldVal) {
				if (newVal) {
					this.items = []
				}
			},
			value(newVal, oldVal) {
				console.log('$attrs.value >>> ' ,newVal)
			}
		},
		mounted() {
			this.selectedItem = this.$attrs.value
		},
		methods: {
			...mapActions([
				'fetchCategoryList'
			]),
			querySearch(q, cb) {
				if (this.items.length > 0) {
					const s = q.trim().toLowerCase()
					if (s.length > 0) {
						console.log(this.items)
						return cb(this.items.filter(({value}) => value.toLowerCase().includes(s)))
					}
					return cb(this.items)
				}
				this.lastAbortController && this.lastAbortController.abort()
				this.initAbortController()
				const signal = this.lastAbortController && this.lastAbortController.signal
				this.fetchCategoryList({params: {channel: this.site}, signal})
					.then(categories => {
						this.items = categories.map(value => {
							return {
								value
							}
						})
						cb(this.items)
					})
			},
			initAbortController() {
				try {
					this.lastAbortController = new AbortController()
				} catch (e) {}
			},
			handleSelect(item) {
				this.selectedItem = item.value
				this.$emit('update', this.selectedItem)
			},
			changedInput(_) {
				this.$emit('update', this.selectedItem)
			}
		}
	}
</script>

<style>
</style>
