<template>
	<el-form-item label="站点:" :prop="prop" :label-width="labelWidth">
		<el-select v-model="selectedSite" filterable @change="onChange" class="w-100" :size="size">
			<el-option v-for="item in websites" :label="item.key" :value="abbr ? item.key : item.value"></el-option>
		</el-select>
	</el-form-item>
</template>

<script>
	import {mapActions} from 'vuex'
	export default {
		props: {
			prop: {
				type: String,
				default: 'channel'
			},
			labelWidth: {
				type: String,
				default: '50px'
			},
			abbr: {
				type: Boolean,
				default: true
			},
			size: {
				type: String,
				default: 'medium'
			}
		},
		data() {
			return {
				websites: [],
				selectedSite: ''
			}
		},
		model: {
			prop: "value",
			event: "update"
		},
		mounted() {
			this.selectedSite = this.$attrs.value
			this.loadChannles()
		},
		watch: {
			'$attrs.value'(newVal, oldVal) {
				this.selectedSite = newVal
			}
		},
		methods: {
			...mapActions([
				'getAllChannels'
			]),
			loadChannles() {
				this.getAllChannels(false)
					.then(data => {
						this.websites = Object.keys(data).map(key => {
							return {
								key,
								value: data[key]
							}
						})
						this.onChange()
					})
			},
			onChange() {
				this.$emit('update', this.selectedSite)
			}
		}
	}
</script>

<style>
</style>
