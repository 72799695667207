<template>
	<div class="d-flex flex-row align-items-center mr-3 mb-4">
		<small v-if="showLabel" style="flex-shrink:0;" class="d-inline-block mr-2">{{label}}</small>
		<el-popover
		  v-if="showTips"
		  placement="top"
		  :width="tipWidth"
		  trigger="hover">
			<template v-for="(tip, index) in tips">
				<!--
				 Slot Usage:
					<div slot="tip" slot-scope="slotProps"><small>{{slotProps.tip}}</small></div> 
				 -->
				<slot name="tip" :tip="tip">
					<div ><small>{{tip}}</small></div>
				</slot>
			</template>
			<sup slot="reference" style="margin-left:-5px;"><i class="el-icon-warning-outline mr-1"></i></sup>
		</el-popover>
		<slot />
	</div>
</template>

<script>
	export default {
		props: {
			label: {
				type: String,
				default: ''
			},
			tips: {
				type: Array,
				default: (() => [])
			},
			tipWidth: {
				type: Number,
				default: 300
			}
		},
		computed: {
			showTips() {
				return this.tips.length > 0
			},
			showLabel() {
				return this.label.length > 0
			}
		}
	}
</script>

<style>
</style>
