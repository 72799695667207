<template>
	<div class="main-tabs">
		<div class="tab-header-container">
			<div class="d-flex flex-row align-items-center tab-header" style="width:max-content;">
				<router-link v-for="(link, index) in links" active-class="tab-active" :to="link.to" :key="index" v-auth="link" :target="target">
					<div class="tab-item">
						{{link.label}}
					</div>
				</router-link>
			</div>
		</div>
		<div style="height:calc(100% - 40px);">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			links: {
				type: Array,
				default: (() => [])
			},
			target: {
				type: String,
				default: '_self'
			}
		}
	}
</script>

<style lang="scss">
	.main-tabs {
		background-color: #f7f7f7;
		box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		border-radius: 3px;
		position: relative;
		height: calc(100% - 40px);
		overflow: hidden;
		flex: 1;
		display: flex;
		flex-direction: column;
		.el-card {
			height: 100%;
			display: flex;
			flex-direction: column;
			.el-card__body {
				height: 100%;
				display: flex;
				flex-direction: column;
			}
		}
		.tab-header-container {
			overflow-x:auto;
			overflow-y:hidden;
			.tab-header {
				height: 40px;
				a:hover {
					text-decoration: none;
				}
				.tab-item {
					padding: 10px;
					font-size: 12px;
				}
				a.tab-active {
					color: white;
					background-color: #409EFF;
					border-bottom: 1px solid #ddd;
				}
				a.router-link-exact-active:hover {
					color: white;
				}
			}
		}
	}	
</style>
