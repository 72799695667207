<template>
	<el-upload action="/adms_api_v0/media/file" accept="image/*" :before-upload="beforeUpload" :disabled="loading || size === null">
		<template v-if="img">
			<div class="d-flex flex-row align-items-start">
				<div slot="trigger" v-loading="loading">
					<img :width="size.width" :height="size.height" style="width:143px;height:auto;"
						:src="img" />
				</div>
				<el-button type="text" class="text-danger ml-2" @click.stop="handleRemove" size="mini" :disabled="loading">删除</el-button>
			</div>
		</template>
		<template v-else>
			<div slot="trigger" class="el-upload--picture-card">
				<i :class="size ? 'el-icon-plus' : 'el-icon-close'" v-loading="loading"></i>
			</div>
		</template>
		<div class="el-upload__tip" slot="tip">
			<template v-if="size">Size: {{size.width}}x{{size.height}}</template>
			<template v-else>无需上传</template>
		</div>
	</el-upload>
</template>

<script>
	import Lodash from 'lodash'
	import {
		fetchJSONByPost
	} from '@/apis'
	export default {
		props: {
			size: {
				type: Object,
				default: (() => {
					return {
						width: 1080,
						height: 1080
					}
				})
			}
		},
		data() {
			return {
				img: '',
				loading: false
			}
		},
		model: {
			prop: "value",
			event: "update"
		},
		watch: {
			'$attrs.value'(newVal, oldVal) {
				console.log(this.$attrs.value)
				this.img = newVal
			}
		},
		mounted() {
			this.img = this.$attrs.value
		},
		methods: {
			handleRemove() {
				this.img = ''
				this.$emit('update', '')
			},
			beforeUpload(file) {
				const src = window.URL.createObjectURL(file)
				const img = new Image()
				img.onload = () => {
					const {
						width,
						height
					} = img
					if (this.size.height === height && this.size.width === width) {
						this.loading = true
						var body = new FormData()
						body.append('file', file)
						const headers = {
							"Content-Type": "multipart/form-data"
						}
						fetchJSONByPost('/adms_api_v0/media/file', body, {
								headers
							})
							.then(({
								src
							}) => {
								this.loading = false
								this.img = src
								this.$emit('update', this.img)
							})
							.catch(err => {
								this.loading = false
								console.log(err)
							})
					} else {
						this.$showErrMsg('上传的图片尺寸不符合要求')
					}
				}
				img.src = src
				return false
			}
		}
	}
</script>

<style>
</style>
