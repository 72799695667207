<template>
	<el-upload
	  :action="action"
	  :show-file-list="false"
	  :accept="accept"
	  :on-progress="uploadOnProgress"
	  :on-success="uploadOnSuccess"
	  :on-error="uploadOnError"
	  :headers="headers"
	  >
	  <el-tooltip :content="tooltip" placement="bottom" effect="light" :disabled="tooltip.length === 0">
	    <el-button type="primary" size="mini">{{title}}</el-button>
	  </el-tooltip>
	</el-upload>
</template>

<script>
	export default {
		inject: ['adminLayout'],
		props: {
			action: {
				type: String,
				default: "/adms_api_v0/product/import"
			},
			accept: {
				type: String,
				default: '.csv'
			},
			title: {
				type: String,
				default: '上传选款'
			},
			tooltip: {
				type: String,
				default: ''
			}
		},
		computed: {
			headers() {
				const Authorization = localStorage.getItem('TOKEN')
				return {Authorization}
			}
		},
		methods: {
			uploadOnProgress(event, file, fileList) {
				this.adminLayout.showLoading()
			},
			uploadOnSuccess(response, file, fileList) {
				this.adminLayout.hideLoading()
				try {
					const {success, errorMsg} = response
					if (!success && errorMsg) {
						this.$showErrMsg(errorMsg)
						return
					}
				} catch (e){
					console.error(e)
				}
				this.$showSuccess('上传成功，请稍后刷新查看')
				this.$emit('callback')
			},
			uploadOnError(err, file, fileList){
				this.$showErrMsg(err)
				this.adminLayout.hideLoading()
			}
		}
	}
</script>

<style>
</style>
