<template>
	<form-item label="拼图模板:">
		<el-select v-model="selectedTemplate" size="mini" clearable @change="$emit('update', selectedTemplate)">
			<el-option v-for="productTemplate in productTemplates" :label="productTemplate" :value="productTemplate"></el-option>
		</el-select>
	</form-item>
</template>

<script>
	import {
		mapActions
	} from 'vuex'
	export default {
		
		data() {
			return {
				selectedTemplate: '',
				productTemplates: []
			}
		},
		model: {
			prop: "value",
			event: "update"
		},
		mounted() {
			this.fetchProductTemplates()
			.then(templates => {
				this.productTemplates = templates
			})
		},
		methods: {
			...mapActions([
				'fetchProductTemplates'
			])
		}
	}
</script>

<style>
</style>
