<template>
	<form-item label="国家:" style="width:300px;">
		<el-select v-model="countryCode" size="mini" clearable class="w-100" filterable @change="onChange">
			<el-option v-if="withAll && countries.length>0" label="ALL" value="ALL">
				<div class="d-flex flex-row align-items-center justify-content-between">
					<div>ALL</div>
					<div>All</div>
				</div>
			</el-option>
			<el-option v-for="country in countries" :label="country.name" :value="country.code">
				<div class="d-flex flex-row align-items-center justify-content-between">
					<div>{{country.code}}</div>
					<div>{{country.name}}</div>
				</div>
			</el-option>
		</el-select>
	</form-item>
</template>

<script>
	export default {
		props: {
			site: {
				type: String,
				default: ''
			},
			withAll: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				countryCode: null,
				countries: [],
				lastAbortController: null,
			}
		},
		model: {
			prop: "value",
			event: "update"
		},
		watch: {
			lastAbortController(newVal, oldVal) {
				oldVal && oldVal.abort()
			},
			site(newVal, oldVal) {
				if (newVal) {
					this.countryCode = null
					this.countries = []
					this.loadCountries()
				}
			},
			value(newVal, oldVal) {
				console.log('$attrs.value >>> ' ,newVal)
			}
		},
		mounted() {
			this.countryCode = this.$attrs.value
		},
		methods: {
			loadCountries() {
				this.lastAbortController && this.lastAbortController.abort()
				this.initAbortController()
				const signal = this.lastAbortController && this.lastAbortController.signal
				this.$store.dispatch('fb-contents-country/getSiteAllCountries', this.site)
				.then(countries => {
					this.countries = countries
				})
			},
			initAbortController() {
				try {
					this.lastAbortController = new AbortController()
				} catch (e) {}
			},
			onChange(_) {
				this.$emit('update', this.countryCode)
			}
		}
	}
</script>

<style>
</style>
