import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import './assets/css/bootstrap.min.css'
import "./assets/css/common.scss"
import router from './router.js'
import store from './store'
import './common/components.js'
import './common/filter.js'
import './common/directive.js'
import './common/global.js'

Vue.config.productionTip = false
const VueInstance = new Vue({
	router,
	store,
	render: h => h(App)
})
VueInstance.$mount('#app')
export default VueInstance


