<template>
	<div>
		<div class="d-flex flex-row flex-wrap">
			<slot />
			<component v-if="advanced && wrapComponent" :is="wrapComponent">
				<el-button type="text" size="mini" data-toggle="collapse" data-target="#advanced" aria-expanded="false" aria-controls="advanced" @click="ariaExpanded=!ariaExpanded">{{ ariaExpanded ? '收起' : '高级查询'}}</el-button>
			</component>
			<el-button v-else-if="advanced && !wrapComponent" type="text" size="mini" data-toggle="collapse" data-target="#advanced" aria-expanded="false" aria-controls="advanced" @click="ariaExpanded=!ariaExpanded">{{ ariaExpanded ? '收起' : '高级查询'}}</el-button>
		</div>
		<div v-if="advanced" id="advanced" class="collapse">
			<div class="d-flex flex-row flex-wrap">
				<slot name="advanced" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			wrapComponent: {
				type: String,
				default: 'form-item'
			}
		},
		data() {
			return {
				ariaExpanded: false,
				advanced: true
			}
		},
		mounted() {
			this.advanced = this.$slots.advanced !== undefined
		}
	}
</script>

<style>
</style>
