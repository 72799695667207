<template>
	<form-item label="渠道:" style="width:280px;" class="form-item-source">
		<el-select value="" size="mini" class="w-75 no-poper" @focus="triggerChannel" :popper-append-to-body="false">
		</el-select>
		<small class="w-25 text-primary ml-1" style="cursor:pointer;" @click="triggerChannel">已选择{{selectedChannels.length}}个</small>
		<el-dialog title="选择渠道" :visible.sync="visible" :before-close="handleClose">
			<el-select v-model="channels" size="mini" clearable class="w-100" filterable multiple>
				<el-option v-for="source in sources" :label="source" :value="source">
				</el-option>
			</el-select>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible = false">取 消</el-button>
				<el-button type="primary" @click="updateChannels">确 定</el-button>
			</div>
		</el-dialog>
	</form-item>
</template>

<script>
	import {
		mapActions
	} from 'vuex'
	import Common from '@/common/mixins/common.js'
	export default {
		mixins: [Common],
		data() {
			return {
				sources: [],
				selectedChannels: [],
				channels: [],
				visible: false
			}
		},
		model: {
			prop: "value",
			event: "update"
		},
		mounted() {
			this.fetchSources()
			.then(sources => {
				this.sources = sources
			})
		},
		methods: {
			...mapActions([
				'fetchSources'
			]),
			triggerChannel() {
				this.channels = [...this.selectedChannels]
				this.visible = true
			},
			updateChannels() {
				this.selectedChannels = [...this.channels]
				this.$emit('update', this.selectedChannels)
				this.visible = false
			},
		}
	}
</script>

<style lang="scss">
	.form-item-source {
		.el-select-dropdown.el-popper {
			display: none;
		}
	}
</style>
