<!-- 内容区 -->
<template>
	<el-container class="d-flex flex-column main-container-box">
		<!-- 内容区头部 -->
		<el-header v-if="showHeader" class="d-flex justify-content-between align-items-center bg-light main-container-header">
			<slot name="header-title">
				<div class="align-items-center m-0 header-title-default">{{$route.meta.title}}</div>
			</slot>
			<slot name="actions"></slot>
		</el-header>
		<!-- 内容区详情 -->
		<el-main class="main-container-content" :class="{'main-container-content-footer': hasPagination, 'mt-0': !showHeader}">
			<slot />
		</el-main>
	</el-container>
</template>

<script>
	export default {
		props: {
			hasPagination: {
				type: Boolean,
				default: false
			},
			showHeader: {
				type: Boolean,
				default: true
			}
		}
	}
</script>

<style>
	.main-container-box {
		overflow: hidden;
		margin: -20px;
		position: relative;
		height:100%;
	}
	.main-container-header {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		box-sizing: border-box;
		z-index: 1000;
	}
	.main-container-header > .main-container-header-default {
		font-size:14px;
		font-weight:bold;
	}
	.main-container-content {
		margin-top: 60px;
		margin-bottom: 0px;
		overflow: auto;
		flex: 1;
	}
	.main-container-content-footer {
		/* margin-bottom: 60px; */
	}
</style>
