<template>
	<el-form-item :label="label" :prop="prop" :label-width="labelWidth" :rules="rules">
		<el-select v-model="countryCode" :size="size" :clearable="clearable" class="w-100" filterable @change="onChange"
			:disabled="disabled">
			<el-option v-if="withAll && countries.length>0" label="ALL" value="ALL">
				<div class="d-flex flex-row align-items-center justify-content-between">
					<div>ALL</div>
					<div>All</div>
				</div>
			</el-option>
			<el-option v-for="country in countries" :label="country.code" :value="country.code">
				<div class="d-flex flex-row align-items-center justify-content-between">
					<div>{{country.code}}</div>
					<div>{{country.name}}</div>
				</div>
			</el-option>
		</el-select>
	</el-form-item>
</template>

<script>
	export default {
		props: {
			site: {
				type: String,
				default: ''
			},
			label: {
				type: String,
				default: '国家缩写:'
			},
			prop: {
				type: String,
				default: 'countryCode'
			},
			size: {
				type: String,
				default: 'large'
			},
			labelWidth: {
				type: String,
				default: '60px'
			},
			clearable: {
				type: Boolean,
				default: true
			},
			disabled: {
				type: Boolean,
				default: false
			},
			rules: {
				type: Object,
				default: (() => {})
			},
			withAll: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				countryCode: '',
				countries: [],
				lastAbortController: null,
			}
		},
		model: {
			prop: "value",
			event: "update"
		},
		watch: {
			lastAbortController(newVal, oldVal) {
				oldVal && oldVal.abort()
			},
			site(newVal, oldVal) {
				if (newVal) {
					if (!this.disabled && oldVal) {
						this.countryCode = ''
						this.onChange()
					} else {
						this.countryCode = this.$attrs.value
					}
					this.countries = []
					this.loadCountries()
				}
			}
		},
		mounted() {
			this.countryCode = this.$attrs.value
			this.loadCountries()
		},
		methods: {
			loadCountries() {
				if (!this.site) return
				this.lastAbortController && this.lastAbortController.abort()
				this.initAbortController()
				const signal = this.lastAbortController && this.lastAbortController.signal
				this.$store.dispatch('fb-contents-country/getSiteAllCountries', this.site)
					.then(countries => {
						this.countries = countries
					})
			},
			initAbortController() {
				try {
					this.lastAbortController = new AbortController()
				} catch (e) {}
			},
			onChange(_) {
				this.$emit('update', this.countryCode)
			}
		}
	}
</script>

<style>
</style>
