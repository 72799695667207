<template>
	<form-item label="语言:">
		<el-select v-model="selectedLanguage" placeholder="请选择" size="mini" clearable filterable
			@change="$emit('update', selectedLanguage)">
			<el-option v-for="lang in languages" :label="lang" :value="lang"></el-option>
		</el-select>
	</form-item>
</template>

<script>
	import {
		mapActions
	} from 'vuex'
	export default {
		data() {
			return {
				selectedLanguage: '',
				languages: []
			}
		},
		model: {
			prop: "value",
			event: "update"
		},
		mounted() {
			this.getShortLanguages()
			.then(languages => {
				this.languages = languages
			})
		},
		methods: {
			...mapActions([
				'getShortLanguages'
			])
		}
	}
</script>

<style>
</style>
