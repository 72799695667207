<template>
	<div>
		<form-item v-if="withLayout" label="发布类型:">
			<el-select v-model="publishType" placeholder="请选择" size="mini" class="w-100" @change="$emit('update', publishType)" :clearable="clearable">
				<el-option v-for="item in publishTypes" :label="item" :value="item"></el-option>
			</el-select>
		</form-item>
		<el-select v-else v-model="publishType" @change="$emit('update', publishType)" :class="addClass" :clearable="clearable">
			<el-option v-for="item in publishTypes" :label="item" :value="item"></el-option>
		</el-select>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'
	export default {
		props: {
			withLayout: {
				type: Boolean,
				default: true
			},
			addClass: {
				type: String,
				default: ''
			},
			disabledItems: {
				type: String,
				default: ''
			},
			clearable: {
				type: Boolean,
				default: false
			},
			platform: {
				type: String,
				default: 'fb'
			}
		},
		data() {
			return {
				publishType: '',
				publishTypes: []
			}
		},
		model: {
			prop: "value",
			event: "update"
		},
		created() {
			if (this.platform === 'fb') {
				this.publishTypes = ['ALL', 'Normal', 'Multiple', 'NEW_TEST']
			} else if (this.platform === 'gg') {
				this.publishTypes = ['UDA']
			}
			this.publishTypes = this.publishTypes.filter(v => this.disabledItems.includes(v) === false)
			this.publishType=this.$attrs.value
		},
		watch: {
			'$attrs.value'(newVal, oldVal) {
				this.publishType = newVal
			}
		}
	}
</script>

<style>
</style>
