import * as XLSX from "xlsx"
export default {
	// sku排列算法
	cartesianProductOf() {
		return Array.prototype.reduce.call(arguments, function(a, b) {
			var ret = [];
			a.forEach(function(a) {
				b.forEach(function(b) {
					ret.push(a.concat([b]));
				});
			});
			return ret;
		}, [
			[]
		]);
	},
	swapArray(arr, index1, index2) {
		arr[index1] = arr.splice(index2, 1, arr[index1])[0];
		return arr;
	},
	// 上移 将当前数组index索引与后面一个元素互换位置，向数组后面移动一位
	moveUp(arr, index) {
		this.swapArray(arr, index, index - 1);
	},
	// 下移 将当前数组index索引与前面一个元素互换位置，向数组前面移动一位
	moveDown(arr, index) {
		this.swapArray(arr, index, index + 1);
	},
	copyToClipboard(text) {
		var i = document.createElement('input')
		i.value = text
		document.body.appendChild(i)
		i.select()
		document.execCommand("Copy")
		document.body.removeChild(i)
		this.$showSuccess('Success copied')
	},
	getIds(text) {
		if (typeof text !== 'string') return []
		return text.replace(/[^0-9]/ig, ' ').split(' ').filter(n => /^\d+$/.test(n))
	},
	ceilIntervalNumber(number, min = 100) {
		var bite = 0;
		if (number < min) {
			return min;
		}
		if (number < 1 && min < 1) {
			number = Math.ceil(number * 1000)
			return this.ceilIntervalNumber(number) / 1000
		}
		while (number >= 10) {
			number /= 10;
			bite += 1;
		}
		return Math.ceil(number) * Math.pow(10, bite);
	},
	base64ConvertFile(urlData) { // 64转file
		if (typeof urlData != 'string') {
			console.error("urlData不是字符串")
			return;
		}
		var arr = urlData.split(',')
		var type = arr[0].match(/:(.*?);/)[1]
		var fileExt = type.split('/')[1]
		var bstr = atob(arr[1])
		var n = bstr.length
		var u8arr = new Uint8Array(n)
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], 'cover.' + fileExt, {
			type: type
		});
	},
	async captureImage(videoFile) {
		const that = this
		return new Promise((resolve, reject) => {
			const video = document.createElement('video')
			video.setAttribute('src', URL.createObjectURL(videoFile))
			video.setAttribute('controls', 'controls')
			video.currentTime = 1
			video.addEventListener('loadeddata', () => {
				const canvas = document.createElement('canvas')
				canvas.width = video.videoWidth
				canvas.height = video.videoHeight
				canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
				const dataURL = canvas.toDataURL('image/png')
				const imgFile = that.base64ConvertFile(dataURL)
				resolve(imgFile)
			})
		})
	},
	exportXlsx(data, filename) {
		const workbook = XLSX.utils.book_new();
		data.forEach(({title, rows}) => {
			const worksheet = XLSX.utils.json_to_sheet(rows)
			XLSX.utils.book_append_sheet(workbook, worksheet, title)
		})
		XLSX.writeFile(workbook, `${filename}.xlsx`);
	}
}