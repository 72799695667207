<template>
	<el-popover
	  v-if="text"
	  trigger="hover">
	  <div style="min-width:100px;max-width:300px;">{{text}}</div>
	  <div slot="reference" class="text-truncate">{{text}}</div>
	</el-popover>
</template>

<script>
	export default {
		props: {
			text: {
				type: String,
				default: ''
			}
		}
	}
</script>

<style>
</style>
