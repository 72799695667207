<template>
	<form-item label="站点:">
		<template v-if="multiple">
			<el-select value="" size="mini" multiple @focus="triggerWebsite" :popper-append-to-body="false" class="form-item-site">
			</el-select>
			<small class="w-25 text-primary ml-1" style="cursor:pointer;"
				@click="triggerWebsite">已选择{{selectedSites.length}}个</small>
			<el-dialog title="选择站点" :visible.sync="visible" :before-close="handleClose">
				<el-select v-model="sites" placeholder="请选择" size="mini" filterable :multiple="multiple" clearable class="w-100">
					<el-option v-for="item in websites" :label="item.key" :value="abbr ? item.key : item.value"></el-option>
				</el-select>
				<div slot="footer" class="dialog-footer">
					<el-button @click="visible = false">取 消</el-button>
					<el-button type="primary" @click="updateWebsites">确 定</el-button>
				</div>
			</el-dialog>
		</template>
		<template v-else>
			<el-select v-model="selectedSites" placeholder="请选择" size="mini" filterable :clearable="clearable" class="w-100" @change="onChange">
				<el-option v-for="item in websites" :label="item.key" :value="abbr ? item.key : item.value"></el-option>
			</el-select>
		</template>
	</form-item>
</template>

<script>
	import {mapActions} from 'vuex'
	import Common from '@/common/mixins/common.js'
	export default {
		mixins: [Common],
		inject: ['adminLayout'],
		props: {
			multiple: {
				type: Boolean,
				default: true
			},
			parent: {
				type: Boolean,
				default: true
			},
			local: {
				type: Boolean,
				default: false
			},
			clearable: {
				type: Boolean,
				default: true
			},
			abbr: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {
				websites: [],
				selectedSites: [],
				sites: [],
				visible: false
			}
		},
		model: {
			prop: "value",
			event: "update"
		},
		mounted() {
			this.__init()
		},
		methods: {
			...mapActions([
				'getAllChannels'
			]),
			__init() {
				if (this.local) {
					this.adminLayout.showLoading()
				}
				this.getAllChannels(this.parent)
					.then(data => {
						this.websites = Object.keys(data).map(key => {
							return {
								key,
								value: data[key]
							}
						})
						if (this.local) {
							const websiteKey = this.$attrs && this.$attrs.value || localStorage.getItem('ORIGIN_CHANNEL') || localStorage.getItem('ORIGIN_CHANNEL:ABBR')
							const website = this.websites.find(v => v.key === websiteKey || v.value === websiteKey) || this.websites[0]
							localStorage.setItem('ORIGIN_CHANNEL', website.value)
							localStorage.setItem('ORIGIN_CHANNEL:ABBR', website.key)
							this.selectedSites = website && (this.abbr ? website.key : website.value)
							this.$emit('update', this.selectedSites)
							this.adminLayout.hideLoading()
							this.$emit('onload')
							this.$emit('siteFullName', website && website.value || '')
						}
					})
					.catch(err => {
						if (this.local) {
							this.adminLayout.hideLoading()
							this.$emit('onerror')
						}
					})
			},
			triggerWebsite() {
				this.sites = [...this.$attrs.value]
				this.visible = true
			},
			updateWebsites() {
				this.$emit('update', [...this.sites])
				this.selectedSites = [...this.sites]
				this.visible = false
			},
			onChange() {
				if (this.local) {
					const website = this.websites.find(v => v.key === this.selectedSites || v.value === this.selectedSites)
					if (website) {
						localStorage.setItem('ORIGIN_CHANNEL:ABBR', website.key)
						localStorage.setItem('ORIGIN_CHANNEL', website.value)
						this.$emit('siteFullName', website && website.value || '')
					}
				}
				this.$emit('update', this.selectedSites)
			}
		}
	}
</script>

<style lang="scss">
	.form-item-site {
		.el-select-dropdown.el-popper {
			display: none;
		}
	}
</style>
