<template>
	<el-upload
	  action="/adms_api_v0/media/file"
	  accept="image/*"
	  :before-upload="beforeUpload"
	  >
	  <el-button slot="trigger" size="mini" type="text">更新图片</el-button>
	</el-upload>
</template>

<script>
	import Lodash from 'lodash'
	import {fetchJSONByPost} from '@/apis/index.js'
	import {updateProductImgApi, updateSingleProductImgApi} from '@/apis/product.js'
	export default {
		inject: ['adminLayout'],
		props: {
			data: {
				type: Object,
				default: (() => {})
			},
			propKey: {
				type: String,
				default: 'picManual'
			},
			imageSize: {
				type: Object,
				default: (() => {
					return {1080: 1080}
				})
			},
			single: {
				type: Boolean,
				default:false
			}
		},
		methods: {
			beforeUpload(file) {
				const src = window.URL.createObjectURL(file)
				const img = new Image()
				img.onload = () => {
					const {width, height} = img
					if (this.imageSize[width] === height) {
						this.adminLayout.showLoading()
						var body = new FormData()
						body.append('file', file)
						const headers = { "Content-Type": "multipart/form-data" }
						fetchJSONByPost('/adms_api_v0/media/file', body, {headers})
						.then(({src}) => {
							const api = this.single ? updateSingleProductImgApi : updateProductImgApi
							api(this.data.id, src)
							.then(res => {
								this.data[this.propKey] = src
								this.adminLayout.hideLoading()
							})
							.catch(err => {
								this.$showErrMsg(err)
								this.adminLayout.hideLoading()
							})
						})
						.catch(err => {
							this.adminLayout.hideLoading()
							console.log(err)
						})
					} else {
						this.$showErrMsg('上传的图片尺寸不符合要求')
					}
				}
				img.src = src
				return false
			}
		}
	}
</script>

<style>
</style>
