<template>
	<div class="d-flex flex-row align-items-center mr-2" @click="chooseItem">
		<div class="radio-dot" :checked="checked"></div>
		<div class="radio-img-box" :checked="checked">
			<el-popover
			  width="400"
			  placement="left"
			  trigger="hover">
				<el-image :src="src" style="width:100%;height:auto;object-fit:cover;" />
				<el-image
					style="width:100%;height:100%;object-fit:cover;"
					slot="reference"
					:src="src"
					:preview-src-list="[src]">
				</el-image>
			</el-popover>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['src', 'checked'],
		methods: {
			chooseItem() {
				this.$emit('change')
			}
		}
	}
</script>

<style lang="scss">
	.radio-dot {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		border: 1px solid #ddd;
		margin-right: 10px;
	}
	.radio-dot[checked] {
		background-color: #409EFF;
		border: 1px solid #409EFF;
	}
	.radio-img-box {
		width: 80px;
		height: 80px;
		border: 1px solid rgba(#000, 0.2);
	}
	.radio-img-box[checked] {
		border: 1px solid #409EFF;
	}
</style>
